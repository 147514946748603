<template>
    <div id="select-include-exclude-from-search">
        <SearchDropdownInput
            :optionList="filteredOptionList"
            @input="( option ) => pushOptionToChild( option )"
        />
        <SelectIncludeExclude
            :initialOptions="selectedOptions"
            :preSelectedOptions="preSelectedOptions"
            :showResetButton="false"
            :showOptionsSeparately="true"
            @updateOptions="( includedOptions, excludedOptions ) => publishSelectedOptions( includedOptions, excludedOptions )"
        />
    </div>
</template>

<script>
// Components
import SearchDropdownInput from '@/components/base/autocomplete/SearchDropdownInput';
import SelectIncludeExclude from '@/components/base/multioption/SelectIncludeExclude';

export default {
    name: 'SelectIncludeExcludeFromSearch',
    components: {
        SelectIncludeExclude,
        SearchDropdownInput,
    },
    props: {
        optionList: {
            required: true,
            type: Array,
        },
    },
    data() {
        return {
            selectedOptions: [],
            includedOptions: [],
            excludedOptions: [],
        };
    },
    computed: {
        preSelectedOptions() {
            return {
                include: this.includedOptions,
                exclude: this.excludedOptions,
            };
        },
        filteredOptionList() {
            return this.optionList.filter( ( option ) => !this.selectedOptions.includes( option ) );
        },
    },
    methods: {
        reset() {
            // can be called from parent by this.$refs.refToThisComponent.reset()
            this.selectedOptions = [];
        },
        pushOptionToChild( supplier ) {
            this.selectedOptions.push( supplier );
            this.includedOptions.push( supplier );
        },
        publishSelectedOptions( includedOptions, excludedOptions ) {
            this.includedOptions = includedOptions;
            this.excludedOptions = excludedOptions;
            this.$emit( 'updateOptions', includedOptions, excludedOptions );
        },
    },
};
</script>
