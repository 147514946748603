<template>
    <div class="custom-container" :class="[ fullSidebar ? 'sidebar-full' : 'sidebar-thin' ]">

        <TheSidebar :fullSize="fullSidebar" />

        <ThePageHeadingBar
            :pageHeading="pageHeading"
            :fullSize="fullSidebar"
        />

        <section class="main">
            <!-- Filters -->
            <div class="autogen-section">
                <div class="autogen-section__header">
                    <h2>Filter By</h2>
                    <ToolTip class="tooltip">
                        <v-icon slot="target" class="tooltip__icon">mdi-information-outline</v-icon>
                        <div slot="content">
                            Supplier and Designators can be included OR excluded.
                        </div>
                    </ToolTip>
                </div>

                <div class="autogen-section__body box-shadow">
                    <!-- Top - Left Column -->
                    <div class="autogen-section__body__column">
                        <!-- Effective date range -->
                        <div class="input-group">
                            <span class="input-group__header">
                                <h3> Sourcing End Date: </h3>
                            </span>
                            <InputDateRange
                                :start-date.sync="sourcingEndDateStart"
                                :end-date.sync="sourcingEndDateEnd"
                                label="Sourcing End Date"
                                outlined
                            />
                        </div>
                        <!-- Supplier -->
                        <div class="input-group">
                            <span class="input-group__header">
                                <h3>
                                    Supplier:
                                </h3>
                                <v-btn
                                    color="error"
                                    outlined
                                    small
                                    @click="resetSuppliers()"
                                >
                                    Reset
                                </v-btn>
                            </span>
                            <span class="input-group__item">
                                <SelectIncludeExcludeFromSearch
                                    ref="SelectIncludeExcludeFromSearch"
                                    class="supplier-autogen"
                                    :optionList="primarySupplierList"
                                    @updateOptions="( includedSuppliers, excludedSuppliers ) => updateSuppliers( includedSuppliers, excludedSuppliers )"
                                />
                            </span>
                        </div>

                    </div>
                    <!-- Top - Right Column -->
                    <div class="autogen-section__body__column">

                        <!-- Designators -->
                        <div class="input-group">
                            <span class="input-group__header">
                                <h3>
                                    Designators:
                                </h3>
                                <v-btn
                                    color="primary"
                                    outlined
                                    small
                                    @click="resetDesignators()"
                                >
                                    Reset
                                </v-btn>
                            </span>
                            <SelectIncludeExclude
                                ref="SelectIncludeExclude"
                                class="designators-autogen"
                                :initialOptions="titleDesignators"
                                :showResetButton="false"
                                @updateOptions="( included, excluded ) => updateTitleDesignators( included, excluded )"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="autogen-section--last">
                <div class="autogen-section__header">
                    <!-- Purchase Order Info -->
                    <h2>
                        Purchase Order Info
                    </h2>
                </div>
                <div class="box-shadow autogen-section__body">

                    <!-- Bottom - Left Column -->
                    <div class="autogen-section__body__column">

                        <!-- Add to Draft -->
                        <div class="input-group input-group--row">
                            <div class="input-group__item">
                                <input
                                    id="draftCheckbox"
                                    v-model="addToDraft"
                                    type="checkbox"
                                >
                            </div>
                            <label class="input-group__header" for="draftCheckbox">
                                <h3>Add to Draft?</h3>
                            </label>
                        </div>

                        <!-- OTB % -->
                        <div class="input-group">
                            <span class="input-group__header">
                                <h3>Open to Buy Percent</h3>
                            </span>
                            <div class="input-group__item">
                                <v-text-field
                                    v-model.number="openToBuyPercent"
                                    type="text"
                                    outlined
                                    dense
                                    hideDetails
                                    appendIcon="mdi-percent-outline"
                                    maxWidth="50%"
                                    @focus="selectInputField()"
                                />
                            </div>
                        </div>

                        <!-- Expected Receive Date -->
                        <div class="input-group">
                            <span class="input-group__header">
                                <h3>Expected Receive Date:</h3>
                            </span>
                            <span class="input-group__item">
                                <InputDatePicker :date.sync="expectedReceiveDate" label="Expected Receive Date" outlined />
                            </span>
                        </div>

                        <!-- Prefix Override -->
                        <div class="input-group flex-column">
                            <span class="input-group__header">
                                <h3>Override PO Number Prefix:</h3>
                            </span>
                            <span class="input-group__item">
                                <v-text-field
                                    v-model="overridePOPrefix"
                                    type="text"
                                    placeholder="Default is PO"
                                    outlined
                                    dense
                                    hideDetails
                                    @focus="selectInputField()"
                                />
                            </span>
                        </div>
                    </div>

                    <!-- Center Column -->
                    <div class="autogen-section__body__column">
                        <v-checkbox
                            v-model="isDigitallyAvailable"
                            label="Don’t Generate if Available Digitally"
                            class="mb-4"
                            hideDetails
                        />

                        <span class="input-group__header">
                            <h3>Days from Sourcing End Date:</h3>
                        </span>
                        <span class="input-group__item">
                            <v-text-field
                                v-model="daysFromSourcingEndDate"
                                type="number"
                                label="Enter Number of Days"
                                outlined
                                dense
                                :disabled="!isDigitallyAvailable"
                                :rules="daysFromSourcingEndDateRules"
                            />
                        </span>

                        <span class="input-group__header">
                            <h3>Minimum PO Quantity</h3>
                        </span>
                        <span class="input-group__item">
                            <v-text-field
                                v-model="minimumPOQuantity"
                                type="number"
                                label="Enter Minimum PO Quantity"
                                outlined
                                dense
                                hideDetails
                            />
                        </span>

                        <span class="input-group__header mt-4">
                            <h3>Minimum Line Quantity</h3>
                        </span>
                        <span class="input-group__item">
                            <v-text-field
                                v-model="minimumLineQuantity"
                                type="number"
                                label="Enter Minimum Line Quantity"
                                outlined
                                dense
                                hideDetails
                            />
                        </span>
                    </div>

                    <!-- Bottom - Right Column -->
                    <div class="autogen-section__body__column">

                        <!-- PO Notes -->
                        <div class="input-group">
                            <div class="input-group__header">
                                <h3>PO Notes</h3>
                            </div>
                            <div class="input-group__body">
                                <v-textarea
                                    v-model="note"
                                    outlined
                                    dense
                                    hideDetails
                                    @focus="selectInputField()"
                                ></v-textarea>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

                <!-- Page Footer Bar -->
                <ThePageFooterBar>
                        <!-- Preview AutoGen -->
                        <v-btn
                            class="footer-preview"
                            color="primary"
                            @click="beginPreview()"
                        >
                            AutoGen Preview
                        </v-btn>
                        <!-- Auto Generate POs Button -->
                        <v-btn
                            color="primary"
                            @click="beginAutoGen()"
                        >
                            Autogenerate POs
                        </v-btn>
                </ThePageFooterBar>
        </section>
    </div>
</template>

<script>
// Vuex
import { mapGetters, mapState } from 'vuex';

// Utils
import FormUtil from '@/utils/FormUtil';

// Components
import ThePageHeadingBar from '@/components/ThePageHeadingBar';
import TheSidebar from '@/views/common/TheSidebar';
import ThePageFooterBar from '@/components/ThePageFooterBar';
import SelectIncludeExclude from '@/components/base/multioption/SelectIncludeExclude';
import ToolTip from '@/components/base/tooltip/ToolTip';
import SelectIncludeExcludeFromSearch from '@/views/PurchaseOrder/autoGen/SelectIncludeExcludeFromSearch';
import InputDateRange from '@/components/InputDateRange';
import InputDatePicker from '@/components/InputDatePicker';

export default {
    name: 'AutoGen',
    components: {
        InputDatePicker,
        InputDateRange,
        ThePageHeadingBar,
        ThePageFooterBar,
        TheSidebar,
        SelectIncludeExclude,
        SelectIncludeExcludeFromSearch,
        ToolTip,
    },
    data() {
        return {
            fullSidebar: false,
            pageHeading: 'AutoGen Purchase Orders',
            sourcingEndDateStart: '',
            sourcingEndDateEnd: '',
            includeSupplierList: [],
            excludeSupplierList: [],
            titleIncludeDesignatorList: [],
            titleExcludeDesignatorList: [],
            addToDraft: false,
            openToBuyPercent: 100,
            expectedReceiveDate: '',
            overridePOPrefix: '',
            note: '',
            overrideEmail: '',
            isDigitallyAvailable: true,
            daysFromSourcingEndDate: '',
            minimumPOQuantity: '',
            minimumLineQuantity: '',
        };
    },
    computed: {
        ...mapGetters( {
            hasLocationList: 'GlobalResources/hasLocationList',
        } ),
        ...mapState( {
            primarySupplierList: ( state ) => state.GlobalResources.primarySupplierList,
            titleDesignators: ( state ) => state.GlobalResources.titleDesignatorList,
        } ),
        daysFromSourcingEndDateRules() {
            if ( !this.isDigitallyAvailable ) return [];

            return [
                ( v ) => !!v || 'Required',
                ( v ) => parseInt( v, 10 ) !== 'NaN' || 'Must be a number',
            ];
        },
    },
    beforeMount() {
        this.$store.dispatch( 'GlobalResources/init' );
    },
    methods: {
        selectInputField( event ) {
            FormUtil.selectInputFieldText( event );
        },
        updateSuppliers( includedSuppliers, excludedSuppliers ) {
            this.includeSupplierList = includedSuppliers;
            this.excludeSupplierList = excludedSuppliers;
        },
        updateTitleDesignators( includedDesignators, excludedDesignators ) {
            this.titleIncludeDesignatorList = includedDesignators;
            this.titleExcludeDesignatorList = excludedDesignators;
        },
        resetSuppliers() {
            this.$refs.SelectIncludeExcludeFromSearch.reset();
        },

        resetDesignators() {
            this.$refs.SelectIncludeExclude.reset();
        },

        beginAutoGen() {
            this.autoGeneratePurchaseOrders();
        },
        autoGeneratePurchaseOrders() {
            this.$store.dispatch( 'PurchaseOrder/autogenActual', this.sanitizedFormData() );
        },

        beginPreview() {
            this.previewAutoGen();
        },
        previewAutoGen() {
            this.$store.dispatch( 'PurchaseOrder/autogenPreview', this.sanitizedFormData() );
        },

        sanitizedFormData() {
            // If we've selected at least one location to filter by,
            // remove the uid from each catalog before we ship it over the endpoint,
            // otherwise send an empty array
            // const formattedCatalogs = this.catalogs[ 0 ].locationId !== 0
            //     ? this.catalogs.map( ( catalog ) => {
            //         delete catalog.uid;
            //         delete catalog.isActivelyBeingEdited;
            //         return catalog;
            //     } )
            //     : [];

            return {
                sourcingEndDateStart: this.sourcingEndDateStart === '' ? null : this.sourcingEndDateStart,
                sourcingEndDateEnd: this.sourcingEndDateEnd === '' ? null : this.sourcingEndDateEnd,
                includeSupplierList: this.includeSupplierList,
                excludeSupplierList: this.excludeSupplierList,
                titleIncludeDesignatorList: this.titleIncludeDesignatorList,
                titleExcludeDesignatorList: this.titleExcludeDesignatorList,
                addToDraft: this.addToDraft,
                openToBuyPercent: this.openToBuyPercent,
                expectedReceiveDate: this.expectedReceiveDate,
                overridePOPrefix: this.overridePOPrefix,
                note: this.note,
                overrideEmail: this.overrideEmail,
                doNotGenerateIfAvailableDigitally: this.isDigitallyAvailable,
                daysOutSkipDigitalAvailabilityGeneration: this.isDigitallyAvailable ? parseInt( this.daysFromSourcingEndDate, 10 ) : 0,
                minimumPOQuantity: this.minimumPOQuantity !== '' ? parseInt( this.minimumPOQuantity, 10 ) : 0,
                minimumLineQuantity: this.minimumLineQuantity !== '' ? parseInt( this.minimumLineQuantity, 10 ) : 0,
            };
        },
    },
};
</script>

<style scoped lang="scss">
    @import "@/assets/sass/variables.scss";
    .autogen-section {
        &__header {
            display: flex;
            align-items: center;
            color: $dark-gray;
        }
        &__body {
            margin: 1rem 0;
            display: grid;
            grid-template-columns: 33.33333% 33.33333% 33.33333%;
            &__column {
                padding: 1rem;

                .invoice-autogen-date-range-picker {
                    ::v-deep.date-container {
                        background-color: transparent;
                        min-width: unset;
                        .duet-date__input {
                            padding: 4px 0 2px 8px;
                            min-height: 40px;
                            font-size: 0.9rem;
                        }

                        .clear-button {
                            height: 37px;
                            width: 24px;
                            right: 35px;

                            i {
                                font-size: 12px;
                                height: 12px;
                                width: 12px;
                            }
                        }

                        .duet-date__toggle {
                            width: 32px;

                            .duet-date__toggle-icon svg {
                                height: 18px;
                                width: 18px;
                            }
                        }
                    }
                }
            }
        }
        &--last {
            // Give space for the content at the bottom, otherwise the footer buttons cover it up
            margin-bottom: 100px;
        }
    }
    .tooltip {
        margin-left: 0.5rem;
        &__icon {
            color: $edit-blue;
        }
    }
    .input-group {
        margin-bottom: 1.5rem;
        &--row {
            display: flex;
            align-items: center;

            .input-group__header {
                margin: auto .5rem;
            }
        }
        &__header {
            color: $dark-gray;
            display: flex;
            justify-content: space-between;
            margin-bottom: 0.5rem;
            h3 {
                font-size: 1rem;
            }
        }
        &__item {
            &--row {
                display: flex;
                align-items: center;
                &__center-text {
                    margin: 0 .5rem;
                }
            }
        }
    }
    .footer-preview {
        margin-right: 1rem;
    }
</style>
